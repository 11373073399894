let t;
const setIntl = (intl) => {
    t = intl
}

function GT(textKey) {
    return t(textKey);
}

class PlaceOrderType {
    static map = {
        1: '联合冰柜',
        2: '自有冰柜'
    }
    static list = Object.keys(PlaceOrderType.map).map(v => ({ label: PlaceOrderType.map[v], value: parseInt(v) }))
}

class Ship2Type {
    static map = {
        1: '经销商仓库',
        // 2: '中外运仓库',
        // 3: '客户总仓',
        // 4: '门店'
    }
    static list = Object.keys(Ship2Type.map).map(v => ({ label: Ship2Type.map[v], value: parseInt(v) }))
}

class PlaceOrderStatus {
    static map = {
        1: { label: '待确认', color: 'orange' },
        2: { label: '已接受', color: 'purple' },
        3: { label: '已拒绝', color: 'red' },
        4: { label: '已取消', color: 'default' },
        5: { label: '待入库', color: 'orange' },
        6: { label: '入库中', color: 'purple' },
        7: { label: '已完成', color: 'default' }
    }
    static list = Object.keys(PlaceOrderStatus.map).map(v => ({ label: PlaceOrderStatus.map[v].label, value: parseInt(v) }))
}

class DepositStatus {
    static map = {
        1: { label: '待缴费', color: 'orange' },
        2: { label: '待确认', color: 'orange' },
        3: { label: '缴费不足', color: 'red' },
        4: { label: '缴费超额', color: 'orange' },
        5: { label: '缴费等额', color: 'green' },
        6: { label: '无需缴费', color: 'green' }
    }
    static list = Object.keys(DepositStatus.map).map(v => ({ label: DepositStatus.map[v].label, value: parseInt(v) }))
}

class ShippingStatus {
    static map = {
        1: '待发货',
        2: '已发货',
        3: '部分发货',
        4: '已签收',
        5: '已取消'
    }
    static list = Object.keys(ShippingStatus.map).map(v => ({ label: ShippingStatus.map[v], value: parseInt(v) }))
}

class SignStatus {
    static map = {
        1: { label: '待签署', color: 'orange' },
        2: { label: '已签署', color: 'green' },
        3: { label: '视同签署', color: 'green' },
    }
    static list = Object.keys(SignStatus.map).map(v => ({ label: SignStatus.map[v].label, value: parseInt(v) }))
}

class SelectionStatus {
    static map = {
        1: { label: '未圈选', color: 'default' },
        2: { label: '已圈选', color: 'green' },
    }
    static list = Object.keys(SelectionStatus.map).map(v => ({ label: SelectionStatus.map[v].label, value: parseInt(v) }))
}

class ExchangeReceiptStatus {
    static map = { 
        1: { label: '待转出方确认', color: 'orange' },
        2: { label: '待转入方确认', color: 'orange' },
        3: { label: '待计算费用', color: 'orange' },
        4: { label: '待审核', color: 'orange' },
        5: { label: '审核通过', color: 'green' },
        6: { label: '待兑付', color: 'orange' },
        7: { label: '已完成', color: 'default' },
        8: { label: '审核未通过', color: 'red' },
        9: { label: '已取消', color: 'default' },
        // 10: { label: '已终止', color: 'default' },
    }
    static list = Object.keys(ExchangeReceiptStatus.map).map(v => ({ label: ExchangeReceiptStatus.map[v].label, value: parseInt(v) }))
}

class PutReceiptStatus {
    static map = { 
        1: { label: '待审核', color: 'orange' },
        2: { label: '审核通过', color: 'green' },
        3: { label: '审核未通过', color: 'red' },
        4: { label: '出库中', color: 'purple' },
        5: { label: '已发货', color: 'purple' },
        6: { label: '已完成', color: 'default' },
        7: { label: '已取消', color: 'default' },
    }
    static list = Object.keys(PutReceiptStatus.map).map(v => ({ label: PutReceiptStatus.map[v].label, value: parseInt(v) }))
}

class TransferReceiptStatus {
    static map = { 
        1: { label: '待审核', color: 'orange' },
        2: { label: '审核通过', color: 'green' },
        3: { label: '审核未通过', color: 'red' },
        // 4: { label: '已终止', color: 'default' },
        5: { label: '已完成', color: 'default' },
        6: { label: '已取消', color: 'default' },
    }
    static list = Object.keys(TransferReceiptStatus.map).map(v => ({ label: TransferReceiptStatus.map[v].label, value: parseInt(v) }))
}

class LostReceiptStatus {
    static map = { 
        0: { label: '待审核', color: 'orange' },
        1: { label: '审核中', color: 'orange' },
        2: { label: '审核通过', color: 'green' },
        3: { label: '审核未通过', color: 'red' },
        4: { label: '已完成', color: 'default' },
        5: { label: '已取消', color: 'default' },
        // 6: { label: '已终止', color: 'default' },
    }
    static list = Object.keys(LostReceiptStatus.map).map(v => ({ label: LostReceiptStatus.map[v].label, value: parseInt(v) }))
}

class LocationType {
    static map = {
        1: '在店',
        2: '在仓',
    }
    static list = Object.keys(LocationType.map).map(v => ({ label: LocationType.map[v], value: parseInt(v) }))
}

class LostReceiptType {
    static map = {
        1: '丢失',
        2: '脱管',
    }
    static list = Object.keys(LostReceiptType.map).map(v => ({ label: LostReceiptType.map[v], value: parseInt(v) }))
}

class LostReason {
    static map = {
        1: '门店倒闭',
        2: '门店拒绝交还',
        3: '经销商丢失',
        4: '其他',
    }
    static list = Object.keys(LostReason.map).map(v => ({ label: LostReason.map[v], value: parseInt(v) }))
}

class RepairLevel {
    static map = {
        0: '未定级',
        1: '小修',
        2: '中修',
        3: '大修',
    }
    static list = Object.keys(RepairLevel.map).map(v => ({ label: RepairLevel.map[v], value: parseInt(v) }))
}

class RepairReceiptStatus {
    static map = { 
        0: { label: '待处理', color: 'orange' },
        1: { label: '待派单', color: 'orange' },
        2: { label: '已派单', color: 'purple' },
        3: { label: '已上门', color: 'purple' },
        // 4: { label: '已完修', color: 'green' },
        5: { label: '待换新', color: 'orange' },
        6: { label: '待验收', color: 'orange' },
        7: { label: '已完成', color: 'default' },
        8: { label: '已取消', color: 'default' },
        // 9: { label: '已终止', color: 'default' },
        10: { label: '验收异常', color: 'red' },
    }
    static list = Object.keys(RepairReceiptStatus.map).map(v => ({ label: RepairReceiptStatus.map[v].label, value: parseInt(v) }))
}

class RepairMeasure {
    static map = {
        1: '换机',
        2: '报废',
        3: '更换配件',
        4: '其它',
    }
    static list = Object.keys(RepairMeasure.map).map(v => ({ label: RepairMeasure.map[v], value: parseInt(v) }))
}

class ScrapReceiptStatus {
    static map = { 
        0: { label: '待审核', color: 'orange' },
        1: { label: '审核中', color: 'orange' },
        2: { label: '审核通过', color: 'green' },
        3: { label: '审核未通过', color: 'red' },
        4: { label: '已完成', color: 'default' },
        5: { label: '已取消', color: 'default' },
        // 6: { label: '已终止', color: 'default' },
        7: { label: '待拍照存档', color: 'orange' },
        8: { label: '存档待确认', color: 'orange' },
    }
    static list = Object.keys(ScrapReceiptStatus.map).map(v => ({ label: ScrapReceiptStatus.map[v].label, value: parseInt(v) }))
}


class AuditStatus {
    static map = { 
        1: { label: '待审核', color: 'orange' },
        2: { label: '审核通过', color: 'green' },
        3: { label: '审核未通过', color: 'red' },
    }
    static list = Object.keys(AuditStatus.map).map(v => ({ label: AuditStatus.map[v].label, value: parseInt(v) }))
}

class AssetStatus {
    static map = { 
        1: { label: '未投放', color: 'default' },
        2: { label: '启用', color: 'default' },
        3: { label: '停用', color: 'default' },
        4: { label: '维修中', color: 'default' },
        5: { label: '已换新', color: 'default' },
        6: { label: '已报废', color: 'default' },
        7: { label: '已丢失', color: 'default' },
        8: { label: '已脱管', color: 'default' },
    }
    static list = Object.keys(AssetStatus.map).map(v => ({ label: AssetStatus.map[v].label, value: parseInt(v) }))
}

class AssetExceptionData {
    static map = { 
        1: '经销商归属异常',
        2: '经销商仓库归属异常',
        3: '仓库未入库异常',
        4: '经销商/销售盘盈',
        5: '经销商/销售盘亏',
        6: '无转投单绑定异常'
    }
    static list = Object.keys(AssetExceptionData.map).map(v => ({ label: AssetExceptionData.map[v], value: parseInt(v) }))
}

class AssetReceiveStatus {
    static map = { 
        1: { label: '未验收', color: 'black' },
        2: { label: '已验收', color: 'green' },
        3: { label: '已拒绝', color: 'red' },
    }
    static list = Object.keys(AssetReceiveStatus.map).map(v => ({ label: AssetReceiveStatus.map[v].label, value: parseInt(v) }))
}

class AssetSymptomType {
    static map = { 
        1: '不通电',
        2: '不制冷',
        3: '跳闸/漏电',
        4: '灯不亮',
        5: '底脚螺栓/底轮损坏、缺失',
        6: '门体损坏/关闭不严',
        7: '有异味',
        8: '噪音大',
        9: '内胆开裂',
        10: '玻璃门碎裂',
        11: '配件缺失',
        12: '其它',
    }
    static list = Object.keys(AssetSymptomType.map).map(v => ({ label: AssetSymptomType.map[v], value: parseInt(v) }))
}

class InventoryTaskStatus {
    static map = { 
        1: { label: '待盘点', color: 'purple' },
        2: { label: '盘点中', color: 'purple' },
        3: { label: '已完成', color: 'default' },
    }
    static list = Object.keys(InventoryTaskStatus.map).map(v => ({ label: InventoryTaskStatus.map[v].label, value: parseInt(v) }))
}

class InventoryAssetStatus {
    static map = { 
        0: { label: '未盘', color: '#30323C' },
        1: { label: '相符', color: '#74BA4A' },
        2: { label: '盘盈', color: '#F77D0C' },
    }
    static list = Object.keys(InventoryAssetStatus.map).map(v => ({ value: parseInt(v), ...InventoryAssetStatus.map[v] }))
}

class InventoryRole {
    static map = { 
        1: '销售',
        2: '经销商',
        3: '物流公司',
        4: '系统',
    }
    static list = Object.keys(InventoryRole.map).map(v => ({ label: InventoryRole.map[v], value: parseInt(v) }))
}

class InventoryFinishType {
    static map = { 
        1: '人工确认完成',
        2: '系统自动完成',
    }
    static list = Object.keys(InventoryFinishType.map).map(v => ({ label: InventoryFinishType.map[v], value: parseInt(v) }))
}
// 状态 0-待审核；1-审核中；2-审核通过；3-审核未通过；4-已完成；5-已取消；6-已终止
class SpecialReportStatus {
    static map = { 
        0: { label: '待审核', color: 'orange' },
        1: { label: '审核中', color: 'orange' },
        2: { label: '审核通过', color: 'success' },
        3: { label: '审核未通过', color: 'red' },
        4: { label: '已完成', color: 'default' },
        5: { label: '已取消', color: 'default' },
        // 6: { label: '已终止', color: 'default' },
    }
    static list = Object.keys(SpecialReportStatus.map).map(v => ({ value: parseInt(v), ...SpecialReportStatus.map[v] }))
}

class LinkReceiptType {
    static map = {
        1: { label: '需求订单', routeName: 'placeOrderDetails', key: 'placeOrder' },
        2: { label: '撤柜单', routeName: 'withdrawReceiptDetails', key: 'withdrawOrder' },
        3: { label: '投放单', routeName: 'putReceiptDetails', key: 'launchOrder' },
        4: { label: '转投单', routeName: 'transferReceiptDetails', key: 'transferOrder' },
        5: { label: '转让单', routeName: 'exchangeReceiptDetails', key: 'exchangeOrder' },
        6: { label: '报废单', routeName: 'scrapReceiptDetails', key: 'scrapOrder' },
        7: { label: '维修单', routeName: 'repairReceiptDetails', key: '' },
        8: { label: '丢失单', routeName: 'lostReceiptDetails', key: 'lostOrder' },
        9: { label: '入库单', routeName: 'inventoryDetails', key: '' },
        10: { label: '发货单', routeName: '', key: '' },
        11: { label: '担保金', routeName: '', key: '' },
        12: { label: '特殊报备单', routeName: 'specialReportDetails', key: 'reportOrder' },
        13: { label: '巡检单', routeName: 'inspectionDetails', key: '' },
        14: { label: '经销商兑付清单', routeName: 'bpRedemption', key: 'operatorRedempteOrder', action: 'code' },
        16: { label: '待兑付清单', routeName: 'pendingRedemption', key: 'pendingRedempteOrder', action: 'code' },
        17: { label: '担保金凭证', routeName: '', key: '', action: 'download' },
    }
    static list = Object.keys(LinkReceiptType.map).map(v => ({ label: LinkReceiptType.map[v].label, value: parseInt(v), key: LinkReceiptType.map[v].key, action: LinkReceiptType.map[v].action }))
}
class AttachmentTagType {
    static map = {
        1: { label: '担保金付款凭证' },
        2: { label: '冰柜协议' },
        3: { label: '余额退付申请表' },
        4: { label: '担保金收据' },
        5: { label: '扣款沟通函' },
        6: { label: '转让协议' },
        7: { label: '合作终止扣款确认书' },
        8: { label: '丢失灭失扣款确认书' },
        9: { label: '其他材料' },
    }
    static list = Object.keys(AttachmentTagType.map).map(v => ({ label: AttachmentTagType.map[v].label, value: parseInt(v) }))
}

// 配置类型
class ConfigType {
    static map = {
        1: { label: '资产类型' },
        6: { label: '文件标签' },
        7: { label: '冰柜协议' },
        8: { label: '上上签协议' },
        9: { label: '场所报备' },
        10: { label: '系统预置' },
        11: { label: '站内信提醒配置' },
        12: { label: '邮件提醒配置' },
    }
    static list = Object.keys(ConfigType.map).map(v => ({ label: ConfigType.map[v].label, value: parseInt(v) }))
}

// 新增中可选的配置类型
class ConfigAddType {
    static map = {
        6: { label: '文件标签' },
        7: { label: '冰柜协议' },
    }
    static list = Object.keys(ConfigAddType.map).map(v => ({ label: ConfigAddType.map[v].label, value: parseInt(v) }))
}

// 担保金状态
class ElectronicDepositStatus {
    static map = {
        1: { label: '待签署', color: 'orange' },
        2: { label: '已签署', color: 'green' },
        3: { label: '视同签署', color: 'green' },
    }
    static list = Object.keys(ElectronicDepositStatus.map).map(v => ({ label: ElectronicDepositStatus.map[v].label, value: parseInt(v) }))
}

// 业务类型
class BizType {
    static map = {
        1: { label: '订单付款' },
        2: { label: '订单取消' },
        3: { label: '转让' },
        4: { label: '灭失扣除' },
        5: { label: '超期扣除' },
        6: { label: '灭失扣除' },
        7: { label: '担保金退付' },
        8: { label: '修正调入' },
        9: { label: '修正调出' },
        10: { label: '财务批量导入资产更新' },
        71: { label: '常规兑换退付' },
        72: { label: '视同常规兑换退付' },
        33: { label: '转入付款' },
        34: { label: '转出扣除' },
    }
    static list = Object.keys(BizType.map).map(v => ({ label: BizType.map[v].label, value: parseInt(v) }))
}

// 收支类型
class PayType {
    static map = {
        2: { label: '支出' },
        1: { label: '收入' },
    }
    static list = Object.keys(PayType.map).map(v => ({ label: PayType.map[v].label, value: parseInt(v) }))
}

function companyType() { // 供应商状态
    return {
        1: '冰柜厂家',   
    }
}

class AssetType {
    static map = { 
        cooler: '冰柜',
        other: '其他',
    }
    static list = Object.keys(AssetType.map).map(v => ({ label: AssetType.map[v], value: v }))
}

// 费用类别 1 = 担保金 2 = 服务费
class FeeTypes {
    static map = {
        1: { label: '担保金' },
        2: { label: '服务费' },
    }
    static list = Object.keys(FeeTypes.map).map(v => ({ label: FeeTypes.map[v].label, value: parseInt(v) }))
}

// 费用类别(DBS) 1 = 担保金
class FeeTypesForDbs {
    static map = {
        1: { label: '担保金' },
    }
    static list = Object.keys(FeeTypesForDbs.map).map(v => ({ label: FeeTypesForDbs.map[v].label, value: parseInt(v) }))
}

// 兑付类型， 1 = 冰柜活动兑付，2 = 冰柜活动视同兑付， 3 = 转让兑付， 4 = 冰柜丢失兑付, 5 = 冰柜报废兑付， 6 = 冰柜逾期未投放兑付， 7 = 冰柜订单取消兑付
class BillTypes {
    static map = {
        1: { label: '冰柜活动兑付' },
        2: { label: '冰柜活动视同兑付' },
        3: { label: '转让兑付' },
        4: { label: '冰柜丢失兑付' },
        5: { label: '冰柜报废兑付' },
        6: { label: '冰柜逾期未投放兑付' },
        7: { label: '冰柜订单取消兑付' },
    }
    static list = Object.keys(BillTypes.map).map(v => ({ label: BillTypes.map[v].label, value: parseInt(v) }))
}
// 兑付状态（1 = 待释放， 2 = 已释放，3 = 终止释放
class BillStatus {
    static map = {
        1: { label: '未释放', color: 'orange' },
        2: { label: '已释放', color: 'success' },
        3: { label: '终止释放', color: 'default' },
    }
    static list = Object.keys(BillStatus.map).map(v => ({ label: BillStatus.map[v].label, value: parseInt(v) }))
}

class EnergyLevels {
    static map = {
        1: { label: '一级' },
        2: { label: '二级' },
        3: { label: '三级' },
        4: { label: '四级' },
        5: { label: '五级' }
    }
    static list = Object.keys(EnergyLevels.map).map(v => ({ label: EnergyLevels.map[v].label, value: parseInt(v) }))
}

// 经销商兑付清单状态:1 = 未审核， 2 = 已审核待签署，3 = 已审核待传输， 4 = 已签署， 5 = 已兑付， 6 = 终止兑付， 7 = 传输结束
class DealersBillStatus {
    static map = {
        1: { label: '未审核', color: 'orange' },
        2: { label: '已审核待签署', color: 'orange' },
        3: { label: '已审核待传输', color: 'orange' },
        4: { label: '已签署', color: 'green' },
        5: { label: '已兑付', color: 'green' },
        6: { label: '终止兑付', color: 'default' },
        7: { label: '传输结束', color: 'default' },
    }
    static list = Object.keys(DealersBillStatus.map).map(v => ({ label: DealersBillStatus.map[v].label, value: parseInt(v) }))
}

// 经销商兑付清单状态（DBS）:1 = 未审核， 2 = 已审核待签署，3 = 已审核待传输， 4 = 已签署， 5 = 已兑付， 6 = 终止兑付， 7 = 传输结束
class DealersBillStatusForDbs {
    static map = {
        4: { label: '已签署', color: 'green' },
        5: { label: '已兑付', color: 'green' },
    }
    static list = Object.keys(DealersBillStatusForDbs.map).map(v => ({ label: DealersBillStatusForDbs.map[v].label, value: parseInt(v) }))
}

// 当日时间段
class DayTimeSlot {
    static map = {
        1: { label: '上午' },
        2: { label: '下午' },
        3: { label: '晚上' }
    }
    static list = Object.keys(DayTimeSlot.map).map(v => ({ label: DayTimeSlot.map[v].label, value: parseInt(v) }))
}

// 撤柜状态
class WithdrawReceiptStatus {
    static map = { 
        0: { label: '待审核', color: 'orange' },
        // 1: { label: '审核中', color: 'orange' },
        2: { label: '审核通过', color: 'green' },
        3: { label: '审核未通过', color: 'red' },
        4: { label: '已完成', color: 'default' },
        5: { label: '已取消', color: 'default' },
        // 6: { label: '已终止', color: 'default' },
    }
    static list = Object.keys(WithdrawReceiptStatus.map).map(v => ({ label: WithdrawReceiptStatus.map[v].label, value: parseInt(v) }))
}

class TaskCenterStatus {
    static map = { 
        0: { label: '待处理', color: 'orange' },
        1: { label: '处理中', color: 'purple' },
        2: { label: '已完成', color: 'green' },
        3: { label: '处理失败', color: 'red' },
        4: { label: '已取消', color: 'default' },
        6: { label: '部分成功', color: 'orange' },
    }
    static list = Object.keys(TaskCenterStatus.map).map(v => ({ label: TaskCenterStatus.map[v].label, value: parseInt(v) }))
}

// 任务中心枚举配置，请注意：新增导入导出code必须配置TaskCenterTypes和ExportTypes
class TaskCenterTypes {
    static map = { 
        1: { label: '导入需求订单' },
        2: { label: '导入设备待兑付清单' },
        3: { label: '导出经销商兑付清单' },
        4: { label: '导入经销商兑付清单更新' },
        5: { label: '导入设备兑付清单更新' },
        6: { label: '导出转让单' },
        7: { label: '导出盘点任务' },
        8: { label: '导出设备待兑付清单' },
        9: { label: '导出盘点追踪' },
        10: { label: '导出未兑付冰柜' },
        11: { label: '导出维修单' },
        12: { label: '导出报废单' },
        13: { label: '导出丢失单' },
        14: { label: '导出撤柜单' },
        15: { label: '导出报备单' },
        16: { label: '导出需求订单' },
        17: { label: '业务批量导入资产' },
        18: { label: '财务批量导入资产' },
        19: { label: '导出投放单' },
        20: { label: '导出巡检历史' },
        21: { label: '导出转投单' },
        22: { label: '导出入库记录' },
        23: { label: '导出冰柜预算' },
        24: { label: '导出冰柜厂' },
        25: { label: '导出经销商担保金历史' },
        26: { label: '导出冰柜资产' },
        27: { label: '导出资产型号' },
        28: { label: '导出门店圈选' },
        29: { label: '导入资产型号' },
        30: { label: '导出盘点资产列表' },
        31: { label: '导出盘点资产流水' },
        32: { label: '导出登录日志' },
        33: { label: '导出业务日志' },
        34: { label: '导出资产日志' },
        35: { label: '批量导出发货信息'}
    }
    static list = Object.keys(TaskCenterTypes.map).map(v => ({ label: TaskCenterTypes.map[v].label, value: parseInt(v) }))
}

class ExportTypes {
    static map = {
        PT_UNKNOWN: 0,
        PT_IMPORT_PLACE_ORDER: 1, //导入需求订单
        PT_IMPORT_ASSET_PAYMENT: 2, //导入设备待兑付清单
        PT_EXPORT_SOLD_TO_PAYMENT: 3, // 导出经销商兑付清单
        PT_IMPORT_SOLD_TO_PAYMENT_UPDATE: 4, // 导入经销商兑付清单更新
        PT_IMPORT_ASSET_PAYMENT_UPDATE: 5, // 导入设备兑付清单更新
        PT_EXPORT_EXCHANGE_RECEIPT: 6, // 导出转让单
        PT_EXPORT_INVENTORY_TASK: 7,   // 导出盘点任务
        PT_EXPORT_ASSET_PAYMENT: 8,    // 导出设备待兑付清单
        PT_EXPORT_INVENTORY_TACK: 9,   // 导出盘点追踪
        PT_EXPORT_UNCLAIMED_ASSET: 10, // 导出未兑付冰柜
        PT_EXPORT_REPAIR_RECEIPT: 11, // 导出维修单
        PT_EXPORT_SCRAP_RECEIPT: 12, // 导出报废单
        PT_EXPORT_LOST_RECEIPT: 13, // 导出丢失单
        PT_EXPORT_WITHDRAW_RECEIPT: 14, // 导出撤柜单
        PT_EXPORT_WHITELIST_RECEIPT: 15, // 导出报备单
        PT_EXPORT_PLACE_ORDER: 16, //导出需求订单
        PT_IMPORT_ASSET_BIZ_UPDATE: 17, // 导入资产更新业务（资产超级入口）
        PT_IMPORT_ASSET_FIN_UPDATE: 18, // 导入资产更新财务（资产超级入口）
        PT_EXPORT_PUT_RECEIPT: 19, // 导出投放单
        PT_EXPORT_PATROL_HISTORY: 20, // 导出巡检历史
        PT_EXPORT_TRANSFER_RECEIPT: 21, // 导出转投单
        PT_EXPORT_ASSET_WAREHOUSE_ENTRY_RECORD: 22, // 导出入库记录
        PT_EXPORT_ASSET_BUDGET: 23, // 导出冰柜预算
        PT_EXPORT_COMPANY: 24, //导出冰柜厂
        PT_EXPORT_SOLD_TO_DEPOSIT_HISTORY: 25, // 导出经销商担保金历史
        PT_EXPORT_ASSET: 26, // 导出冰柜资产
        PT_EXPORT_ASSET_MODEL: 27, //导出资产型号
        PT_EXPORT_STORE_SELECT: 28, //导出门店圈选
        PT_IMPORT_ASSET_MODULE: 29, //导入资产型号
        PT_EXPORT_INVENTORY_ASSET: 30, // 导出盘点资产列表
        PT_EXPORT_INVENTORY_ASSET_FLOW: 31, // 导出盘点资产流水
        PT_EXPORT_LOGIN_LOG: 32, // 导出登录日志
        PT_EXPORT_BIZ_LOG: 33, // 导出业务日志
        PT_EXPORT_ASSET_LOG: 34, // 导出资产日志
        PT_EXPORT_PLACE_ORDER_SHIPPING_INFO: 35 // 批量导出发货信息
    }
}

// 费用类目
class BusinessTypes {
    static map = { 
        1: { label: '对账差异修正调入' },
        2: { label: '对账差异修正调出' }
    }
    static list = Object.keys(BusinessTypes.map).map(v => ({ label: BusinessTypes.map[v].label, value: parseInt(v) }))
}

// 操作类型
class OperateTypes {
    static map = { 
        1: { label: '位置变更' },
        2: { label: '活动期数变更' },
        3: { label: '费用变更' },
        4: { label: '状态变更' },
        5: { label: '归属工单变更' },
        6: { label: '异常变更' },
        7: { label: '告警变更' },
        8: { label: '经销商归属变更' },
        9: { label: '其余变更' }
    }
    static list = Object.keys(OperateTypes.map).map(v => ({ label: OperateTypes.map[v].label, value: parseInt(v) }))
}

// 工单审批状态
class ReceiptAuditStatus {
    static map = { 
        1: { label: '审核通过', color: 'green' },
        2: { label: '审核未通过', color: 'red' },
    }
    static list = Object.keys(ReceiptAuditStatus.map).map(v => ({ label: ReceiptAuditStatus.map[v].label, value: parseInt(v) }))
}

// 登录状态
class LoginStatus {
    static map = { 
        1: { label: '成功', color: 'green' },
        2: { label: '失败', color: 'red' },
    }
    static list = Object.keys(LoginStatus.map).map(v => ({ label: LoginStatus.map[v].label, value: parseInt(v) }))
}

// 业务日志类型
class BizLogTypes {
    static map = { 
        1: { label: '操作日志' },
        2: { label: '审批日志' },
    }
    static list = Object.keys(BizLogTypes.map).map(v => ({ label: BizLogTypes.map[v].label, value: parseInt(v) }))
}

// 业务日志模块名称
class BizLogModuleTypes {
    static map = {
        1: { label: '项目管理' },
        2: { label: '需求订单' },
        3: { label: '门店圈选' },
        4: { label: '投放单' },
        5: { label: '巡检' },
        6: { label: '转投单' },
        7: { label: '撤柜单' },
        8: { label: '转让单' },
        9: { label: '丢失单' },
        10: { label: '特殊报备' },
        11: { label: '维修单' },
        12: { label: '报废单' },
        13: { label: '盘点管理' },
        14: { label: '盘点任务' },
        15: { label: '盘点追踪' },
        16: { label: '厂商' },
        17: { label: '资产型号' },
        18: { label: '资产管理' },
        19: { label: '经销商资料' },
        20: { label: '担保金' },
        21: { label: '待兑付清单' },
        22: { label: '经销商兑付清单' },
        23: { label: '资产预算' },
        24: { label: '任务中心' },
        25: { label: '通知中心' }
    };
    static list = Object.keys(BizLogModuleTypes.map).map(v => ({ label: BizLogModuleTypes.map[v].label, value: parseInt(v) }))
}

// 业务日志操作类型
class BizLogOperateTypes {
    static map = {
        1: { label: '创建' },
        2: { label: '取消' },
        3: { label: '编辑' },
        4: { label: '审核' },
        5: { label: '恢复' },
        6: { label: '完成' },
        7: { label: '删除' }
    };
    static list = Object.keys(BizLogOperateTypes.map).map(v => ({ label: BizLogOperateTypes.map[v].label, value: parseInt(v) }))
}

class CloseOpinionTypes {
    static map = {
        1: { label: '运作正常' },
        2: { label: '运作异常' },
        3: { label: '非维保范围内损坏' },
    };
    static list = Object.keys(CloseOpinionTypes.map).map(v => ({ label: CloseOpinionTypes.map[v].label, value: parseInt(v) }))
}

export {
    AssetExceptionData,
    AssetReceiveStatus, AssetStatus, AssetSymptomType, AssetType, AttachmentTagType, AuditStatus, BillStatus, BillTypes, BizLogModuleTypes, BizLogOperateTypes, BizLogTypes, BizType, BusinessTypes, ConfigAddType, ConfigType, DayTimeSlot, DealersBillStatus,
    DealersBillStatusForDbs, DepositStatus, ElectronicDepositStatus, EnergyLevels, ExchangeReceiptStatus, ExportTypes, FeeTypes,
    FeeTypesForDbs, GT, InventoryAssetStatus, InventoryFinishType, InventoryRole, InventoryTaskStatus, LinkReceiptType, LocationType, LoginStatus, LostReason, LostReceiptStatus, LostReceiptType, OperateTypes, PayType, PlaceOrderStatus, PlaceOrderType, PutReceiptStatus, ReceiptAuditStatus, RepairLevel, RepairMeasure, RepairReceiptStatus,
    ScrapReceiptStatus, SelectionStatus, Ship2Type, ShippingStatus, SignStatus, SpecialReportStatus, TaskCenterStatus, TaskCenterTypes, TransferReceiptStatus, WithdrawReceiptStatus, CloseOpinionTypes, companyType, setIntl
};

